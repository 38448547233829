var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Module for interacting with API endpoints relating to the Birth Records feature
 */
import { apiApplicationsBase } from "@/features/applications/api";
import { apiGet, apiPost, apiPut, getFullyQualifiedAPIUrl, uploadFile } from "@/core/api";
export var apiBirthRecords = __assign(__assign({}, apiApplicationsBase("birthRecords")), { sendViaMail: function (applicationId) {
        return apiPost("applications/birthRecords/".concat(applicationId, ":sendViaMail"), null);
    }, setFillingDate: function (applicationId, fillingDate) {
        return apiPut("applications/birthRecords/".concat(applicationId, "/fillingDate"), fillingDate);
    }, setNotarizationRequired: function (applicationId, required) {
        return apiPut("applications/birthRecords/".concat(applicationId, "/notarization/required"), required);
    }, getBlankNotaryFormViewLink: function (applicationId) {
        return apiGet("applications/birthRecords/".concat(applicationId, "/viewToken"), "text/plain")
            .then(function (viewToken) { return getFullyQualifiedAPIUrl("applications/birthRecords/".concat(applicationId, "/notarization/blankForm?token=").concat(encodeURIComponent(viewToken))); });
    }, uploadSignedNotaryForm: function (applicationId, file) {
        return uploadFile(file, ["PDF"])
            .then(function (document) { return apiPut("applications/birthRecords/".concat(applicationId, "/notarization/signedForm"), document); });
    }, uploadNotarizedNotaryForm: function (applicationId, file) {
        return uploadFile(file, ["PDF"])
            .then(function (document) { return apiPut("applications/birthRecords/".concat(applicationId, "/notarization/notarizedForm"), document); });
    } });
