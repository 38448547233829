/*
 * Main entry-point - initializes and mounts the Vue app
 */
import App from '@/App.vue';
import router from '@/router';
import { createApp } from 'vue';
import { STATIC_CONFIG } from './core/config';
console.log("Mini City Portal - Version " + STATIC_CONFIG.VERSION);
// Init styles - includes Bulma, as well as our own custom global styles
require('@/assets/styles.scss');
// Init Vue
var app = createApp(App);
// Standard components - we register these globally for convenience / brevity 
import DocumentUploadButton from "@/components/DocumentUploadButton.vue";
import DocumentUploadWidget from "@/components/DocumentUploadWidget.vue";
import FormCheckboxField from "@/components/FormCheckboxField.vue";
import FormCodeEditorField from "@/components/FormCodeEditorField.vue";
import FormDateField from "@/components/FormDateField.vue";
import FormNumberField from "@/components/FormNumberField.vue";
import FormSelectField from "@/components/FormSelectField.vue";
import FormStringListField from "@/components/FormStringListField.vue";
import FormTextField from "@/components/FormTextField.vue";
import LoadingPage from "@/components/LoadingPage.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import LoadingTableRow from "@/components/LoadingTableRow.vue";
import ModalDialog from "@/components/ModalDialog.vue";
import SaveButton from "@/components/SaveButton.vue";
import TabsHeader from "@/components/TabsHeader.vue";
import HeaderSectionLayout from "@/layouts/HeaderSectionLayout.vue";
import CommentSection from "@/features/comments/CommentSection.vue";
import OpenTasksIndicator from "@/features/tasks/OpenTasksIndicator.vue";
import TaskSection from "@/features/tasks/TaskSection.vue";
app.component('DocumentUploadButton', DocumentUploadButton);
app.component('DocumentUploadWidget', DocumentUploadWidget);
app.component('FormCheckboxField', FormCheckboxField);
app.component('FormCodeEditorField', FormCodeEditorField);
app.component('FormDateField', FormDateField);
app.component('FormSelectField', FormSelectField);
app.component('FormStringListField', FormStringListField);
app.component('FormTextField', FormTextField);
app.component('FormNumberField', FormNumberField);
app.component('HeaderSectionLayout', HeaderSectionLayout);
app.component('LoadingPage', LoadingPage);
app.component('LoadingSpinner', LoadingSpinner);
app.component('LoadingTableRow', LoadingTableRow);
app.component('ModalDialog', ModalDialog);
app.component('SaveButton', SaveButton);
app.component('TabsHeader', TabsHeader);
app.component('CommentSection', CommentSection);
app.component('TaskSection', TaskSection);
app.component('OpenTasksIndicator', OpenTasksIndicator);
import UserBadge from "@/core/UserBadge.vue";
import CitizenBadge from "@/features/citizens/CitizenBadge.vue";
import PartnerBadge from "@/features/partners/PartnerBadge.vue";
import SiteBadge from "@/features/sites/SiteBadge.vue";
app.component('UserBadge', UserBadge);
app.component('PartnerBadge', PartnerBadge);
app.component('SiteBadge', SiteBadge);
app.component('CitizenBadge', CitizenBadge);
app.config.globalProperties.autoLogoutTimer = null;
app.use(router).mount('#app');
