import { createRouter, createWebHistory } from "vue-router";
import Login from '@/views/Login.vue';
import Main from '@/views/Main.vue';
import PasswordReset from '@/views/PasswordReset.vue';
import PartnerOnboarding from '@/features/partnerOnboarding/views/PartnerOnboarding.vue';
import PartnerUserOnboarding from '@/features/partnerOnboarding/views/PartnerUserOnboarding.vue';
import ExternalNotarization from '@/features/notarization/views/ExternalNotarization.vue';
import CitizenAnalyticsPanel from '@/features/citizenAnalytics/CitizenAnalyticsPanel.vue';
import BirthRecordDetailPanel from '@/features/applications/birthrecords//BirthRecordDetailPanel.vue';
import BirthRecordListingPanel from '@/features/applications/birthrecords/BirthRecordListingPanel.vue';
import GeorgiaBenefitsDetailPanel from '@/features/applications/georgia_dfcs_benefits/GeorgiaBenefitsDetailPanel.vue';
import GeorgiaBenefitsListingPanel from '@/features/applications/georgia_dfcs_benefits/GeorgiaBenefitsListingPanel.vue';
import SocialSecurityDetailPanel from '@/features/applications/social_security/SocialSecurityDetailPanel.vue';
import SocialSecurityListingPanel from '@/features/applications/social_security/SocialSecurityListingPanel.vue';
import CitizenDetailPanel from '@/features/citizens/CitizenDetailPanel.vue';
import CitizenListingPanel from '@/features/citizens/CitizenListingPanel.vue';
import PartnerUserDetailPanel from '@/features/partnerUsers/PartnerUserDetailPanel.vue';
import PartnerUserListingPanel from '@/features/partnerUsers/PartnerUserListingPanel.vue';
import PartnerDetailPanel from '@/features/partners/PartnerDetailPanel.vue';
import PartnerListingPanel from '@/features/partners/PartnerListingPanel.vue';
import SiteDetailPanel from '@/features/sites/SiteDetailPanel.vue';
import SiteListingPanel from '@/features/sites/SiteListingPanel.vue';
import MyTasksListingPanel from '@/features/tasks/MyTasksListingPanel.vue';
import UserSettingsPanel from '@/features/userSettings/UserSettingsPanel.vue';
var routes = [
    { path: '/login', name: 'Login', component: Login },
    { path: '/passwordReset', name: 'PasswordReset', component: PasswordReset },
    { path: '/onboarding/partner/:inviteCode', component: PartnerOnboarding, props: true },
    { path: '/onboarding/partnerUser/:inviteCode', component: PartnerUserOnboarding, props: true },
    { path: '/notarization/:applicationId/:token', component: ExternalNotarization, props: true },
    {
        path: '/', name: 'Main', component: Main,
        children: [
            { path: '/citizens/:citizenId', component: CitizenDetailPanel, props: true },
            { path: '/citizens', component: CitizenListingPanel },
            { path: '/birthRecords/:applicationId', component: BirthRecordDetailPanel, props: true },
            { path: '/birthRecords', component: BirthRecordListingPanel },
            { path: '/socialSecurity/:applicationId', component: SocialSecurityDetailPanel, props: true },
            { path: '/socialSecurity', component: SocialSecurityListingPanel },
            { path: '/georgiaBenefits/:applicationId', component: GeorgiaBenefitsDetailPanel, props: true },
            { path: '/georgiaBenefits/', component: GeorgiaBenefitsListingPanel, props: true },
            { path: '/partners/:partnerId', component: PartnerDetailPanel, props: true },
            { path: '/partners/', component: PartnerListingPanel, props: true },
            { path: '/sites/:siteId', component: SiteDetailPanel, props: true },
            { path: '/sites/', component: SiteListingPanel, props: true },
            { path: '/partnerUsers/:partnerUserId', component: PartnerUserDetailPanel, props: true },
            { path: '/partnerUsers/', component: PartnerUserListingPanel, props: true },
            { path: '/citizenAnalytics', component: CitizenAnalyticsPanel, props: true },
            { path: '/tasks', component: MyTasksListingPanel },
            { path: '/userSettings', component: UserSettingsPanel },
        ]
    }
];
var router = createRouter({
    history: createWebHistory(),
    routes: routes
});
export default router;
